body, html {
    background-color: #f7f7f7;
    margin: 0;
    padding: 0;
    font-size: 12.5px;
    height: 100%;

    /* this prevents changing fontsize in landscape mobile */
    -webkit-text-size-adjust: 100%;
    -moz-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
}

div#root, div#root > div {
    height: 100%;
}

button.ButtonWithoutText > span.MuiButton-icon { 
    margin: 0;
}

@media only screen and (max-width: 599px) {
    div.MuiAccordionSummary-content {
        margin-right: -18.5px;
    }

    div.MuiAccordionSummary-content.Mui-expanded {
        margin: 20px -18.5px 20px 0;
    }
}
